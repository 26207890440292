"use client";

import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { useToast } from "./ui/use-toast";
import { performPayment, getSVGToken } from "@/lib/actions";
import { Loader2 } from "lucide-react";

const FormSchema = z.object({
  address: z.string().length(34, {
    message: "Address must be 34 characters.",
  }),
  token: z.string().length(8, { message: "Token must be 8 characters." }),
});

export default function AddressForm() {
  const [svg, setSvg] = useState<string>("");
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const [paymentPending, setPaymentPending] = useState<boolean>(false);
  const { toast } = useToast();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      address: "",
      token: "",
    },
  });

  const handleGetCoins = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const address = getValues("address");
    if (!address || address.length != 34) {
      toast({
        title: "Please enter your FTC address",
        description: "Address must be 34 characters.",
        variant: "destructive",
      });
      return;
    }
    setTokenLoading(true);
    const tokenSvg = await getSVGToken();
    setSvg(tokenSvg);
    setTokenLoading(false);
    form.setFocus("token");
  };

  useEffect(() => {
    if (svg) {
      form.setFocus("token");
    } else {
      form.setFocus("address");
    }
  }, [form, svg]);

  const getValues = form.getValues;

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    // console.log(data);
    // toast({
    //   title: "You submitted the following values:",
    //   description: (
    //     <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
    //       <code className="text-white">{JSON.stringify(data, null, 2)}</code>
    //     </pre>
    //   ),
    // });
    setPaymentPending(true);
    const response = await performPayment(data);
    setPaymentPending(false);
    if (!response) return;

    if ("error" in response) {
      toast({
        title: "Error",
        description: response.error,
        variant: "destructive",
      });
    } else if ("message" in response) {
      toast({
        description: response.message,
        duration: 10000,
        style: { backgroundColor: "#fff", color: "#000" },
      });
    }
    setSvg("");
    form.reset();
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <div className="flex flex-col items-center space-y-4">
                <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-3">
                  <FormLabel>
                    Enter your FTC address (don&apos;t use SegWit):
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="w-80"
                      placeholder="FTC address"
                      required
                      {...field}
                    />
                  </FormControl>
                  <a href="#help">Help</a>
                </div>
                <FormMessage />
                <Button
                  type={svg ? "button" : "submit"} // If 'svg' is not present, this is the submit button
                  disabled={tokenLoading}
                  onClick={handleGetCoins}
                >
                  {" "}
                  {tokenLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Get coins
                </Button>
                <div dangerouslySetInnerHTML={{ __html: svg }} />
              </div>
            </FormItem>
          )}
        />
        {svg && (
          <div>
            <FormField
              control={form.control}
              name="token"
              render={({ field }) => (
                <div className="flex flex-col items-center space-y-4">
                  <FormItem className="flex flex-col items-center">
                    <div className="flex items-center space-x-2">
                      <FormLabel>Enter token from the image above:</FormLabel>
                      <FormControl>
                        <Input
                          className="w-24"
                          placeholder="token"
                          {...field}
                        />
                      </FormControl>
                    </div>
                    <FormMessage />
                    <Button type="submit" disabled={paymentPending}>
                      {paymentPending && (
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Submit Token
                    </Button>
                  </FormItem>
                </div>
              )}
            />
          </div>
        )}
      </form>
    </Form>
  );
}
